$baseColor: #0D1B2A;
$buttonColor: #1B263B;
$midBlue: #415A77;
$silverBlue: #778DA9;
$platinum: #E0E1DD;

$monoBlue: #72A0B8;

::-webkit-scrollbar {
  display: none;
}

:root {
  // React-Toastify overrides - https://fkhadra.github.io/react-toastify/how-to-style#override-css-variables
  --toastify-color-success: #66bb6a;

  --background-light-blue: #F0F6FA;
  --background-light-green: #F1FAF5;

  --mui-blue: #1976d2;
  --google-blue: #0060B8;
  --google-green: #388e3c;
  --google-red: #d32f2f;

  --google-light-green: rgba(129, 199, 132, 0.5);
  --google-light-red: rgba(229, 115, 115, 0.6);

  --dark-blue: #263238;
  --leaseDarkBlue: #2267CE;
  --leaseLightBlue: #2B8EEA;

  --purchaseDarkGreen: #007D6E;
  --purchaseLightGreen: #1BA897;
  --grey-text: #666666;
  --danger-red: #A23325;
  --off-white: #F6F6F6;
  --grey: #C4C4C4;
}

.App {
  text-align: center;
  color: $baseColor;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  .danger {
    color: var(--danger-red);
  }
}

button {
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
}

.loading {
  background:
          linear-gradient(90deg,#0001 33%,#0005 50%,#0001 66%)
          rgba(0, 0, 0, 0.11);
  background-size:300% 100%;
  animation: l1 2s ease-in-out 0.5s infinite;
  color: transparent;
  user-select: none;
  cursor: none;
}

.form-errors {
  color: red;
  font-size: 14px;
}


input[type="text"], input[type="password"], textarea {
 all: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


a {
  all: unset;
}

strong {
  color: $buttonColor;
}

.Modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  p: 4
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 35px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50% !important;
  --_m:
          conic-gradient(#0000 10%,#000),
          linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;

  color: #FFFFFF;
  background: #2b8eea;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.pac-container{
  position: absolute;
  z-index: 999999;
}

.PopoverInput-textarea {
  padding: 10px;
}

.MuiSkeleton-root {
  -webkit-transform: scale(1, 0.80) !important;
  -moz-transform: scale(1, 0.80) !important;;
  -ms-transform: scale(1, 0.80) !important;;
  transform: scale(1, 0.8) !important;;
}

.CustomInput {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: fixed;

  &.disabled {
    box-shadow: none;
  }
}

.MuiDrawer-root {
  z-index: 8888 !important;
}