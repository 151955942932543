#LoginPage {
    background: rgb(38, 50, 56);
    height: 100vh;

    .container {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #efefef;
        max-width: 450px;
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
    .input-group {
        width: 100%;
        display: flex;
        align-items: center;

        .login-page_input {
            width: 350px;
            height: 44px;
            flex-shrink: 0;

            border: 1px solid #C4C4C4;
            background: #F6F6F6;
            outline: none;
            z-index: inherit;
            padding: 0 10px;
            margin: 10px 0;
            border-radius: 5px;
        }
    }

    button {
        color: rgb(38, 50, 56);

        &.disabled {
            opacity: .5;
        }
    }

    @media (max-width: 1224px) {
        .container {
            height: 350px;
            min-height: 350px;
            width: 350px;
        }
        .input-group {


            .login-page_input {
                width: 275px;
            }
        }
    }
}