#ProfilePage {
  background: #FFFFFF;
  //padding: 120px 0px;
  display: flex;
  overflow: hidden;

  flex-direction: column;

  @media (max-width: 1224px) {
    padding: 60px 24px;
    overflow-y: scroll;
  }

  .ProfilePage_container {
    max-width: 1000px;
  }

  .ProfilePage_section {
    background: #EFEFEF;
    padding: 50px;
    margin: 20px;
    border-radius: 8px;

    .settings-header {
      color: #9BA1A3;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        height: 30px;
        width: 30px;
      }
    }
  }

  .ChangePassword {
    display: flex;
    flex-direction: column;
    width: max-content;

    .password-icon {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }

    form {
      display: flex;
      flex-direction: column;
    }


    label {
      color: #9BA1A3;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      align-items: center;
    }

    input {
      box-sizing: border-box;
      width: 350px;
      height: 44px;
      flex-shrink: 0;
      border: 1px solid #C4C4C4;
      background: #F6F6F6;
      outline: none;
      z-index: inherit;
      padding: 0 10px;
      margin: 10px 0;
    }

    button {
      width: 182px;
      height: 36px;
      flex-shrink: 0;
      border-radius: 2px;
      background: #2B8EEA !important;
      border: none;
      color: #FFFFFF;
      align-self: end;
      margin-top: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.28px;
      font-size: 14px;
    }
  }
}

.CreateLocationInput {
  display: flex;
  flex-direction: column;

  .CreateLocationInput_button {
    width: 182px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #2B8EEA !important;
    border: none;
    color: #FFFFFF;
    align-self: end;
    margin-top: 19px;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.28px;

  }

  .CreateLocationInput_input {
    width: 350px;
    height: 44px;
    flex-shrink: 0;
    border: 1px solid #C4C4C4;
    background: #F6F6F6;
    outline: none;
    z-index: inherit;
  }
}

.LocationsTable {
  .LocationsTable_header {
    display: flex;
    color: #9BA1A3;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 100% */
    align-items: center;

    svg {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
  }

  label {
    color: #6B7377;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    margin-bottom: 7px;
  }

  button {
    cursor: pointer;
    width: 182px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #2B8EEA !important;
    border: none;
    color: #FFFFFF;
    align-self: end;
    margin-top: 19px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.28px;

    &.disabled {
      cursor: not-allowed;
    }
  }
  .LocationItem {
    display: flex;
    align-items: center;
    width: 370px;
    height: 56px;
    padding: 0px 16px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--Border-Light-Secondary, #E4E2E4);
    background: #FFFFFF;

    &.selected {
      color: #FFF;
      background: #2B8EEA;
    }

    .LocationItem_address {
      flex-grow: 1;

      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */

      &.selected {
        color: #FFF;
      }
    }

    .LocationItem-icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      color: #6c757d;
      cursor: pointer;

      &.selected {
        opacity: .54;
      }

      &.star {
        cursor: auto;
      }
    }
  }
}