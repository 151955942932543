#DealPage {
  overflow-y: auto;
  background: #F9F9F9;
  padding: 29px 50px;
  display: inline-block;
  min-height: 150vh;

  @media (max-width: 1224px) {
    padding: 29px 32px;
    width: auto;
    display: flex;
    justify-content: center;
  }

  .DealPage-header {
    margin-bottom: 37px;
    height: 61px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10px 20px;

    .lock-icon {
      margin-right: 10px;
      cursor: pointer;
    }

    .lender-details_container {
      display: flex;
      justify-content: space-between;
    }

    .header-title {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 100% */
      letter-spacing: 0.15px;
      display: flex;
      align-items: center;

      @media (max-width: 1224px) {
        margin-left: 20px;
      }

      a {
        cursor: pointer;
        margin-left: 8px;
      }
    }

    .header-subtitle {
      color: var(--on-surface-medium-emphasis-60, rgba(0, 0, 0, 0.60));
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.15px;
    }

    .press-enter {
      font-size: 14px;
      margin-left: 10px;
    }

    .header-button {
      box-sizing: border-box;
      border-radius: 4px;
      border: none;
      height: 36px;
      flex-shrink: 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: var(--White-Surface, #FFF);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.28px;
      border-radius: 4px;

      &.crm {
        margin-right: 5px;
        padding: 6px;
        background-color: var(--White-Surface, #FFF);
      }

      &.tools {
        padding: 6px 16px 6px 23px;
      }

      @media (max-width: 1224px) {
        width: auto;
        padding: 6px;
        margin-right: 20px;
        margin-bottom: 0px;
      }

      &.mobile {
        width: auto;
        padding: 6px;
      }

      svg {
        margin-left: 4px;
        border-radius: 4px;
      }
    }

    .pdf-dropdown-container {
      background: #EFEFEF;
      border: none;
      border-radius: 2px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

  .lender-details_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    span {
      margin: 2px;
    }

    .action-icon {
      cursor: pointer;
      height: 30px;
      width: 30px;
      fill: #FFFFFF;
      border-radius: 4px;
    }
  }

  .DealPageInfo_box-container {
    width: 100%;

    @media only screen and (max-width: 820px) {
      flex-direction: column;
    }

    .DealPageInfo_box {
      background: #FFFFFF;
      width: 365px;
      height: 170px;
      flex-shrink: 0;
      padding: 15px 22px 30px 20px;
      margin-bottom: 24px;
      border-radius: 8px;
      position: relative;

      svg {
        background-color: var(--leaseLightBlue);
        color: #FFFFFF;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 4px;
      }

      &.isPurchase {
        svg {
          background-color: var(--purchaseLightGreen);
        }
      }

      @media only screen and (max-width: 820px) {
        width: 100%;
      }
    }
  }
  .DealPageInfo_input {
    color: var(--grey-text);
    width: 100%;

    .MuiInput-root {
      width: 100%;

      input {
        width: 100%;
        color: var(--grey-text);
        text-overflow: ellipsis;
      }
    }
  }

  .DealPageInfo_description {
    text-overflow: ellipsis;
    max-height: 126px;
    width: 100%;
    margin-top: 5px;

    &.loading {
      background:
              linear-gradient(90deg,#0001 33%,#0005 50%,#0001 66%)
              rgba(0, 0, 0, 0.11);
      background-size:300% 100%;
      animation: l1 2s ease-in-out 0.5s infinite;
      color: transparent;
      user-select: none;
      cursor: none;
    }
  }

  .header {
    color: var(--on-surface-medium-emphasis-60, rgba(0, 0, 0, 0.60));
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
    font-weight: 400;
  }

  .location-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dealPagePayment {
    .lender-name {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 4px;
    }
  }

  .dealPagePayment-column {
    background: #FFF;
    min-width: 365px;
    height: 430px;
    flex-shrink: 0;
    padding: 15px 22px 30px 22px;
    border-radius: 8px;

    &.mobile {
      height: auto;
      width: 100%;
      margin-bottom: 20px;
      padding: 20px 15px;
    }

    .divider {
      margin-bottom: 15px;
    }

    .payment-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      align-items: center;

      .MuiOutlinedInput-root {
        padding-right: 0;
      }

      .payment-row_name {
        color: #666;
        text-align: center;
        font-size: 14px;
      }

      input,
      .money_factor_override_container {
        box-sizing: border-box;
        text-align: end;
        display: flex;
        width: 100px;
        height: 30px;
        padding: 0px 5px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        outline: none;
        border: none;
      }

      .select {
        .MuiOutlinedInput-root {
          padding: 0;
        }

        input {
          padding: 0 0 0 10px;
          text-align: start;
        }
      }

      .money_factor_override_container {
        width: auto;
        input {
          all: unset;
        }
      }

      .disabled {
        //background: #FFFFFF;
        border: none;

        fieldset {
          border: none;
        }
      }

      @keyframes l1 {
        0% {background-position: right}
      }
    }

    .dealPage-button {
      box-sizing: border-box;
      border-radius: 2px;
      border: none;
      background: #2B8EEA;
      height: 36px;
      flex-shrink: 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 16px 6px 23px;
      margin-bottom: 6px;
      width: 150px;
      float: right;

      color: var(--White-Surface, #FFF);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.28px;
      border-radius: 4px;

      @media (max-width: 1224px) {
        width: auto;
        padding: 6px;
      }

      &.isDisabled {
        opacity: .5;
      }

      &.mobile {
        width: auto;
        padding: 6px;
      }

      svg {
        margin-left: 4px;
        border-radius: 4px;
      }
    }
  }


  .dealPageFees {
    justify-content: space-between;

    .leftColumn {
      display: flex;
      flex-direction: column;
    }

    .rightColumn {
      display: flex;
      flex-direction: column;
    }
  }

  .dealPageFees {
    .finance-all {
      box-sizing: border-box;
      border-radius: 2px;
      border: none;
      background: #2B8EEA;
      height: 32px;
      flex-shrink: 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 16px 6px 16px;
      margin-bottom: 6px;
      color: var(--White-Surface, #FFF);
      font-feature-settings: "clig" off, "liga" off;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.28px;
      text-transform: capitalize;
    }
  }

  .dealPageRebates, .dealPageFees {
    margin-top: 30px;
    padding: 23px 22px;
    display: block;
    background: #FFFFFF;
    border-radius: 8px;

    table {
      table-layout: fixed;
      max-width: 706px;
    }

    .header-amount {
      font-size: 16px;
      margin-right: 10px;
    }

    .DealPageFeeList {
      padding: 15px 0px;
      font-size: 14px;

      .feesList-sum {
        text-align: end;
        input {
          box-sizing: border-box;
          text-align: end;
          display: flex;
          width: 70px;
          height: 30px;
          padding: 0px 5px;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          font-weight: 500;
          font-size: 14px;
          border-radius: 4px;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }



      td {
        width: 100%;

        &:last-child {
          width: 120px;
        }
      }

    }

    @media (max-width: 1224px) {
      display: flex;
      padding: 20px 15px;
      min-width: 365px;
    }

    .header-row {
      padding: 0px 10px;

      .header-name {
        color: var(--on-surface-medium-emphasis-60, rgba(0, 0, 0, 0.60));
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.15px;
      }
    }

    &.mobile {
      width: 100%;
      flex-direction: column;

      .MuiTableCell-root {
        padding: 6px 0;
      }
    }

    .fee-name {
      color: #666666;
      padding: 6px 0;
    }

    .mobileBlue {
      color: #2B8EEA;
    }

    .header-cell {
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      //
      //@media (max-width: 1224px) {
      //  color: #2B8EEA;
      //}

      &.mobileBlue {
        color: #2B8EEA;
      }
    }

    .fees-total {
      td {
        //border-bottom: none;
        //border-top: 1px solid rgba(224, 224, 224, 1);
      }
    }
  }

  .Mui-checked {
    color: var(--leaseLightBlue)
  }

  .MuiSwitch-track {
    background-color: var(--leaseLightBlue);
  }

  &.isPurchase {
    .Mui-checked {
      color: var(--purchaseLightGreen)
    }

    .MuiSwitch-track {
      background-color: var(--purchaseLightGreen);
    }
  }

  .isDisabled {
    .Mui-checked {
      opacity: .5;
      color: var(--grey-text)
    }

    .MuiSwitch-track {
      background-color: var(--grey-text);
    }
  }
}

.DescriptionIcon {
  cursor: pointer;
  fill: #9BA1A3 !important;
  margin-left: 10px;
  float: right;
}

.DealRebateModal-name {
  color: var(--on-surface-medium-emphasis-60, rgba(0, 0, 0, 0.60));
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
}

.DealRebateModal-description {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.deal-header-dropdown-menu-container {
  div, a {
    border-radius: 8px;
  }

  .deal-header-dropdown-menu {
    margin: 10px;

    .header-button {
      padding: 10px 20px;
      cursor: pointer;
      color: var(--grey-text);
      display: flex;

      svg {
        //margin-right: 20px;
        color: var(--leaseLightBlue);

        &.isPurchase {
          color: var(--purchaseLightGreen)
        }

      }

      &:hover {
        background: var(--leaseLightBlue);
        color: #FFFFFF;

        &.isPurchase {
          background: var(--purchaseLightGreen);
        }

        svg {
          color: #FFFFFF;
        }
      }
    }
  }
}


.payment-row_value {
  &.loading {
    background:
            linear-gradient(90deg,#0001 33%,#0005 50%,#0001 66%)
            rgba(0, 0, 0, 0.11);
    opacity: .5;
    background-size:300% 100%;
    animation: l1 2s ease-in-out 0.5s infinite;
    color: transparent;
    user-select: none;
    cursor: none;
  }
}

.custom-fee-input {
  .MuiOutlinedInput-input {
    width: 75px;
    padding: 5px;
    font-size: 14px;
  }
}

.feesCategory {
  cursor: pointer;
  &.open {
    opacity: .2;
  }

  &.open {
    td.DealPageFeeList-headers {
      //border-bottom: none !important;
    }
  }
}

.CustomerPDF-input {
  border-radius: 4px;
  box-sizing: border-box;
  width: 350px;
  height: 44px;
  flex-shrink: 0;
  border: 1px solid #C4C4C4;
  background: #F6F6F6;
  outline: none;
  z-index: inherit;
  padding: 0 10px;
  margin-top: 10px;
}