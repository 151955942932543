@import '../../App.scss';

#PricingListTableHeaders {
  height: 160px;

  th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;


    color: #666;
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 49px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &.year-header {
      width: 30px;
    }

    .header-div {
      position: absolute;
      width: 100%;
      padding: 10px;
      bottom: 0;
      border-bottom: 1px solid #E0E0E0;
      border-top: 1px solid #E0E0E0;
    }

  }


}