#CalculatePage {
  overflow-y: auto;
  background: #F9F9F9;
  padding: 29px 50px;
  display: flex;
  height: 100vh;

  @media (max-width: 1224px) {
    padding: 29px 20px;
    height: 100%;
  }

  .CalcPage-header {
    margin-bottom: 37px;
    height: 61px;
    background: var(--leaseDarkBlue);

    .lender-details_container {
      display: flex;
      justify-content: space-between;
    }

    .header-title {
      color: #FFFFFF;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 100% */
      letter-spacing: 0.15px;
      display: flex;
      align-items: center;
      margin-left: 81px;

      @media (max-width: 1224px) {
        font-size: 14px;
      }

      .select-deal-type {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
        letter-spacing: 0.15px;
        color: #FFFFFF;

        .MuiOutlinedInput-notchedOutline {
          border: none;
        }

        @media (max-width: 1224px) {
          font-size: 14px;
        }

        .MuiSelect-select {
          display: flex;
          align-items: center;
          border: none;
        }
        svg {
          color: #FFFFFF;
        }
      }

      a {
        cursor: pointer;
        margin-left: 8px;
      }
    }

    .header-subtitle {
      color: var(--on-surface-medium-emphasis-60, rgba(0, 0, 0, 0.60));
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.15px;
    }

    .press-enter {
      font-size: 14px;
      margin-left: 10px;
      color: #FFFFFF;
    }

    .header-button {
      box-sizing: border-box;
      border-radius: 2px;
      border: none;
      background: #2B8EEA;
      height: 36px;
      flex-shrink: 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 16px 6px 23px;
      margin-right: 47px;

      color: var(--White-Surface, #FFF);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.28px;

      @media (max-width: 1224px) {
        width: auto;
        padding: 6px;
      }

      &.mobile {
        width: auto;
        padding: 6px;
      }
    }

    .pdf-dropdown-container {
      background: #EFEFEF;
      border: none;
      border-radius: 2px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

  .CalcPageInfo_box {
    background: #FFFFFF;
    width: 365px;
    flex-shrink: 0;
    padding: 15px 22px 30px 20px;
    margin-bottom: 200px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    height: 400px;
    margin-right: 20px;

    .select-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;
      font-size: 18px;

      label {
        color: var(--grey-text);
        font-size: 14px;
        font-weight: 500;
      }

      .Select {
        width: 229px;

        font-weight: 600;
        color: #000000;
        background: #F6F6F6;
        border: 1px solid #E5E5E5;
        height: 32px;

        .MuiOutlinedInput-notchedOutline {
          border: none;
          outline: none;
        }
      }

      input {
        width: 229px;
        color: #000000;
        background: #F6F6F6;
        border: 1px solid #E5E5E5;
        height: 32px;
        border-radius: 4px;
        padding: 1px 2px;
        font-size: 13px;
        font-weight: 400;
        box-sizing: border-box;
      }
    }

    .payment-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;

      .payment-row_name {
        font-size: 14px;
        color: var(--grey-text)
      }

      input {
        box-sizing: border-box;
        text-align: end;
        display: flex;
        width: 100px;
        height: 30px;
        padding: 0px 5px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        outline: none;
        border: none;
      }

      .disabled {
        border: none;

        fieldset {
          border: none;
        }
      }
    }

    .Pinned-locations-container {
      overflow-y: scroll;
      height: 200px;
    }

    button {
      margin-top: 10px;
      float: right;
      text-transform: capitalize;
      color: white;
      width: 100px;

      &.isDisabled {
        opacity: .5;
      }

      svg {
        margin-left: 5px;
      }

      .--CircularProgress-progressColor, .CircularProgress-progressColor {
        color: var(--purchaseLightGreen) !important;
        fill: var(--purchaseLightGreen);
      }
    }

    @media (max-width: 1224px) {
      margin-bottom: 20px;
      height: auto;
      margin-right: 0px;
    }
  }

  .calc-reset-button {
    margin-left: 10px;
    text-transform: capitalize;
    color: white;

    &.isDisabled {
      opacity: .5;
    }

    svg {
      margin-left: 5px;
    }
  }

}