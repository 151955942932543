@import '../../App.scss';

#PricingListPage {
  font-size: 13px;
  display: flex;
  position: relative;

  .PricingListTableSearchBarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .PricingListTableSearchBarHeader_make-name {
      color: var(--on-surface-medium-emphasis-60, rgba(0, 0, 0, 0.60));
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 24px;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.15px;
    }

    .PricingListTableSearchBarHeader_search-container {
      width: 350px;
      height: 34px;
      flex-shrink: 0;
      border: 1px solid #C4C4C4;
      background: #F6F6F6;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        height: inherit;
        border-radius: 2px;
      }
      svg {
        color: #666666;
      }
    }
  }

  .ExpandCircleUpSharpIcon {
    transform: rotate(180deg);
  }

  .ExpandMoreIcon {
    color: #333333;
    fill: rgba(0, 0, 0, 0.40);
  }

  table {
    border-collapse: collapse;
    padding: 20px;
    table-layout: fixed;
    height: fit-content;
    width: 100%;

    tr:nth-child(even){background-color: #F6F6F6;;}

    .sticky-year-td {
      position: sticky;
      z-index: 1000;
      background: #FFFFFF;
    }


    .PricingListTable-year_name-button {
      display: flex;
      gap: 10px;
    }

    .PricingListTable-model_name-button {
      display: flex;
      width: 120px;
      padding: 10px;
      align-items: center;
      gap: 10px;
      font-weight: bold;
      white-space: pre;

      td {
        border: none;
        color: $buttonColor;
      }
    }

  }

  td {
    vertical-align: middle;
    text-align: start;
    table-layout: fixed;

    width: 66px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    input {
      font-size: 14px;
      width: 90px;
      text-overflow: ellipsis;
      border-radius: 4px;
      text-align: right;
      padding: 2px 4px;


      @media (max-width: 1791px)  {
        width: 70px;
      }

    }

    svg {
      cursor: pointer;
    }

    &.delete-pricing-row-icon {
      display: flex;
      justify-content: end;
      height: 100%;
      width: 95%;
    }

    .action-icon {
      height: 30px;
      width: 30px;
      fill: #FFFFFF;
      background: #2B8EEA;
      margin-left: 2px;
      border-radius: 4px;

      &.garbage {
        fill: var(--grey-text);
        background: transparent;
        height: 21px;
      }

      &.purchase {
        background: var(--purchaseLightGreen);
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }

    &.pricing-list-row_description {
      padding-right: 20px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .pricingListTableBody {
    margin: 20px;
  }

  .year-row {
    display: flex;
    //border: 1px solid black;
  }

  .model-row {
    display: flex;
    //border: 1px solid black;

    &:first-of-type {
      border-top: none;
    }
  }

  .trim-sd-name {
    width: 180px;
  }

  .trim-name {
    //min-width: 150px;
    font-weight: bold;
  }

  .sd-name {
    color: #999;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .model-cell {
    width: 100px;
  }

  .pricingBrandsContainer {
    display: flex;
    background: #EFEFEF;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    padding: 5px;

    .editIcon {
      cursor: pointer;

      &.isEditing {
        color: #2B8EEA;
      }
    }


    .pricingBrandsSearchContainer {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      margin-top: 36px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .pricingBrands {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .navlink-container {
    display: flex;
    align-items: center;

    &.isHovered {
      border-top: 2px solid #2B8EEA;
    }

    .deal-icon {
      visibility: hidden;
      background: #2B8EEA;
      color: #FFFFFF;
      height: 28px;
      width: 28px;

      &.unhide {
        visibility: visible;
      }
    }

    .brand-navlink {
      display: flex;
      min-height: 24px;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      color: #000;
      font-size: 13px;
      padding: 2px 10px;
      cursor: pointer;

      .pricingBrandsCellChevronRightIcon {
        display: none;
        cursor: pointer;
      }

      &.hasDeal {
        background: #E4E4E4;
      }

      &:hover, &.active {
        background: #0160B8;
        color: #FFFFFF;

        .pricingBrandsCellChevronRightIcon {
          display: inherit;
        }
      }
    }

    .bookmarkIcon {
      cursor: pointer;
      color:  rgb(38, 50, 56);
      height: 28px;
      width: 28px;
    }

    .brand-navlink__edit {
      color: #000;
      font-size: 13px;
      padding: 2px 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .draghandle {
        cursor: grab;
        color:  rgb(38, 50, 56);
      }
    }
  }
}

.trim-container {
  display: flex;
  flex-direction: column;

  .trim-name {
    font-size: 13px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    margin-bottom: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sd-name {
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
    text-align: left;
    color: rgba(153, 153, 153, 1);
  }
}