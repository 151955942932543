#HomePage {
  display: flex;
  position: relative;

  .staging-warning {
    position: fixed;
    height: 40px;
    background-color: #CE8922;
    top: 0;
    z-index: 20;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
  }

  .MobileAppBar {
    z-index: 9999;
    box-shadow: none;
    background: #263238;

    .mobile-toolbar {
      display: flex;
      justify-content: space-between;
    }
  }

  .MobileAppBarDrawer {
    top: 57px;
  }

  .AppSidebarNav__container {
    background: #263238;
    height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 9999;

    &.hidden {
      span, h3, h5 {
        display: none;
      }
    }

    .AppSidebarNav-info {
      color: #9BA1A3;
      height: 140px;

      .arrow-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
        cursor: pointer;

        &:hover svg {
          background: rgba(148, 148, 148, 0.12);
        }

        &.pinned svg {
          color: #FFF;
          background: rgba(148, 148, 148, 0.12);
        }
      }
    }

    .divider {
      background: #FFFFFF;
      border-width: 1px;
    }


    .AppSidebarNav {
      background: #263238;
      box-sizing: border-box;
      height: calc(100% - 140px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .AppSidebarNav-top {
        color: #FFF;

        .navlink-container {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

}

.navlink {
  color: #9BA1A3;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  cursor: pointer;
  width: inherit;
  display: flex;
  padding: 5px 5px;
  margin: 5px 5px;

  &.active, &:hover {
    color: #FFF;
    background: rgba(148, 148, 148, 0.12);
    border-radius: 4px;
  }
}



.AppNavLinks {
  background: #263238;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  span {
    margin-left: 10px;
  }
}