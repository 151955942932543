#DeskingPage {
  .DeskingPage-Box {
    padding: 41px 89px;
    max-width: 100vw;
    //max-height: 90vh;
    overflow: hidden;

    .desking-scroll-container {
      overflow: scroll;
      max-height: 90vh;
      max-width: 95vw;
    }

    @media (max-width: 1224px) {
      padding: 41px 16px;
    }

    .car-info {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;

      a {
        margin-right: 10px;
        cursor: pointer;
        color: black;
      }
    }
  }

  input[type="text"], input[type="password"], textarea {
    border: none;
    outline: none;
    background: inherit;
  }

  table {
    border-collapse: collapse;

    padding: 20px;
    background: #FFFFFF;

    thead {
      color: #666;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-top: 1px solid #E0E0E0;

      th {
        padding: 10px;
        font-weight: 400;
        height: 50px;
        width: 150px;

        &.term {
          position: sticky;
          left: -1px;
          background: #FFFFFF;
          //border-right: 1px solid #E0E0E0;
          box-shadow: inset -1px 0 0 0 #E0E0E0;
        }

        @media (max-width: 1224px) {
          &.term {
            position: sticky;
            left: -1px;
            background: #FFFFFF;
            //border-right: 1px solid #E0E0E0;
            box-shadow: inset -1px 0 0 0 #E0E0E0;
          }
        }

      }
    }

    tr {
      border-bottom: 1px solid #E0E0E0;
    }

  }

  td {
    vertical-align: middle;
    //width: 200px;
    table-layout: fixed;
    text-align: center;
    padding: 10px 5px;

    &.quote {
      .info {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #F6F6F6;
        align-items: center;
        padding: 10px 5px;
        width: 115px;
        border-radius: 4px;


        @media (max-width: 1224px) {
          width: 81px;
          height: 60px;
          font-size: 14px;
          padding: 10px;
        }

        svg {
          color: #9BA1A3;
        }

        &.selected {
          background: var(--leaseLightBlue);
        }

        &:hover {
          background: var(--leaseLightBlue);
        }

        &.isPurchase {
          &.selected {
            background: var(--purchaseLightGreen);
          }

          &:hover {
            background: var(--purchaseLightGreen);
          }
        }

        .info-payment {
          text-align: end;
        }

        .month {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0em;
        }
      }
    }

    &.term {
      position: sticky;
      left: -1px;
      background: #FFFFFF;
      box-shadow: inset -1px 0 1px 0 #E0E0E0;
    }

    @media (max-width: 1224px) {
      &.term {
        position: sticky;
        left: -1px;
        background: #FFFFFF;
        box-shadow: inset -1px 0 1px 0 #E0E0E0;
      }
    }

    &.row-name {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #666;

      @media (max-width: 1224px) {
        font-size: 14px;
      }

    }

    svg {
      cursor: pointer;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .pricingListTableBody {
    margin: 20px;
  }

  .year-row {
    display: flex;
    //border: 1px solid black;
  }

  .model-row {
    display: flex;
    //border: 1px solid black;

    &:first-of-type {
      border-top: none;
    }
  }

  .trim-name {
    min-width: 150px;
  }

  .model-cell {
    width: 100px;
  }
}


.DeskingModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: none;
  border-radius: 8px;
  outline: none;

  @media (max-width: 1224px) {
    width: 100%;
    top: 52%;
  }
}

.desking-sort__drop-down-item {
  cursor: pointer;
  padding: 5px 10px;
  margin: 2px 0;
  border-radius: 4px;

  &:hover {
    background-color: #F8F8F8;
  }
}