#ListDealsPage {
  background: #EFEFEF;

  .ListDealsPage_header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    padding-right: 20px;

    .ListDealsPage_header-name {
      color: #9BA1A3;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
    }

    .ListDealsPage_header-search-container {
      width: 350px;
      height: 34px;
      flex-shrink: 0;
      border: 1px solid #C4C4C4;
      background: #F6F6F6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;

      input {
        width: 100%;
      }
    }
  }

  .ListDealsList {
    height: 100%;
    overflow: auto;

    .ListDealsList_container {
      display: flex;
      flex-wrap: wrap;
    }

    .ListDealCard {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 16px;
      background: #FFF;
      border-radius: 8px;
      width: calc((100% - 60px) / 3);
      flex: 0 0 30%;
      position: relative;

      .action-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px;
        color: #FFF;
      }

      &.mobile {
        flex-direction: column;
        padding: 10px;
      }

      .left {
        padding: 35px 24px 32px 35px;

        label {
          font-weight: bold;
          color: #263238;
          border-bottom: 1px solid #263238;
          margin-top: 10px;
          margin-bottom: 5px;
        }

        .ListDealCard-description {
          max-height: 110px;
          overflow: scroll;
        }
      }

      .right {
        padding: 35px 24px 32px 35px;
        background: #2267CE;
        color: #FFFFFF;
        min-width: 149px;
        border-top-right-radius: 8px;
      }


      &.selected, &:hover {
        background: #2B8EEA;
        color: #FFFFFF;

        label {
          color: #FFFFFF;
          border-bottom: 1px solid #FFFFFF;
        }
      }

      &.isPurchase {
        .right {
          background: #007D6E;
        }
      }

      &.selected.isPurchase {
        background: #1BA897;
      }

      &:hover.isPurchase {
        background: #1BA897;
      }


      .top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }

      .bottom {
        border-top: 1px solid white;
      }

      .pay-detail_value {
        &.loading {
          background:
                  linear-gradient(90deg,#0001 33%,#0005 50%,#0001 66%)
                  rgba(0, 0, 0, 0.11);
          background-size:300% 100%;
          animation: l1 2s ease-in-out 0.5s infinite;
          color: transparent;
          user-select: none;
          cursor: none;
        }
      }
    }
  }
}


.ListDealCard-menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--danger-red);
    color: white;
  }
}