#PricingListPageMobile {
  width: 100%;

  .PricingListPageMobile_select-car {
    padding: 27px 36px 27px 34px;
  }

  .CreatePricingSectionMobile {
    padding: 27px 36px 27px 34px;
    border-top: 1px solid rgba(224, 224, 224, 1);

    input {
      align-items: center;
      border: 1px solid rgba(229, 229, 229, 1);
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      gap: 10px;
      height: 24px;
      justify-content: flex-end;
      padding: 0 5px;
      text-align: end;
      width: 155px;
      background: rgba(246, 246, 246, 1);
      color: rgba(155, 161, 163, 1);
    }

    button {
      width: 139px;
      height: 36px;
      background: rgba(43, 142, 234, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 0;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-align: center;
      margin-top: 21px;
    }
  }

  h3 {
    margin-bottom: 22px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
  }

  label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }


  .select-rows-container {

    .select-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      align-items: center;

      .trim-select {
        height: 51px;
      }

    }
  }

  .PricingListPageMobile-Select {
    width: 229px;

    font-weight: 600;
    color: #000000;
    background: #F6F6F6;
    border: 1px solid #E5E5E5;
    height: 32px;

    .MuiOutlinedInput-notchedOutline {
      border: none;
      outline: none;
    }
  }
}

.PricingsListTableMobile {
  padding: 27px 36px 27px 34px;
  border-top: 1px solid rgba(224, 224, 224, 1);

  .PricingsListTableMobile_table {
    tr {
      width: 100%;
    }
    tr:nth-child(even){background-color: #F6F6F6;;}

    .PricingsListTableMobile_table-headers {

    }
    .PricingsListTableMobile_table-rows {

    }
  }
}