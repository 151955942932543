#CRMPage {
  background: #FFFFFF;
  width: 100%;
  position: relative;

  #CRMHeader {
    width: 100%;
    margin-bottom: 30px;

    .CRM_header-search-container {
      width: 410px;
      color: var(--grey-text);
      border: 1px solid var(--grey);
      background: #F6F6F6;
      position: relative;
      margin-left: 32px;
      height: 100%;

      input
      {
        width: 100%;
        padding: 7px 5px;

        &:focus {
          background: #F6F6F6 !important;
        }
      }

      svg {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    .upload-csv-button {
      background: var(--background-light-blue);
      border: 1px solid var(--google-blue);
      border-radius: 0;
      margin-left: 10px;
      text-transform: unset;
    }
  }

  .crm_table_header {
    background-color: #F8F8F8;
    //border: 1px solid #E0E0E0;

    color: #666666;
  }

  .LeadsPage {
    .LeadsTable {
      width: 100%;
      overflow: auto;

      .LeadsTable-container {
        overflow-y: scroll;
      }
    }

  }

  th, td {
    font-size: 14px;
    border: 1px solid #E0E0E0;
    font-weight: 400;
    height: 40px;
    padding: 5px 10px;

    &:focus-within {
      background: var(--background-light-blue);
    }
  }

  input {
    //padding: 5px 10px;
    outline: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;

    &:focus {
      background: var(--background-light-blue);
    }
  }


  th {
    position: sticky;
    z-index: 1000;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    border-top: none;
    border-bottom: none;
    top: 0;
    box-shadow: inset 0 -1px #E0E0E0, inset 0 1px #E0E0E0; // border top and bottom
  }

  tbody {
    overflow-y: scroll;


    tr:first-child td {
      border-top: none;
    }
  }
}

#LeadsPage, #ClientsPage {
  .CreateLeadItem-row, .CreateClientItem-row {
    //border: 1px solid var(--purchaseLightGreen);

    .CreateLeadItem-th, .CreateClientItem-th, .ClientItem-td, .ClientItem {
      border-top: none;
      position: sticky;
      top: 40px;
      background-color: var(--background-light-green);
      box-shadow: inset 0 -1px #E0E0E0; // only border bottom

      input {
        padding: 0;
      }

      &.quotes, &.deal {
        color: var(--leaseDarkBlue);
        font-weight: 500;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      input {
        background: var(--background-light-green);
      }
    }
  }

  .LeadsTable, .ClientsTable {
    span {
      //padding: 0;
    }

    td, th {
      &.select {
        //width: 42px;
        text-align: center;
        svg {
          font-size: 1.2rem;
        }
      }
    }
  }

  .LeadItem, .ClientItem {
    width: 100%;
    height: 100%;

    .LeadItem-td_quotes, .ClientItem-td_deal {
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--leaseDarkBlue);
      font-weight: 500;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

    }

    td {
      text-overflow: ellipsis;
    }

    .lead-item-quote-summary {
      font-size: 8px;
    }
  }
}


.parent-scroll-ref {
  overflow: auto;
  height: 100%;
}

.ClientItem-td__status-select {
  width: 100%;
  input {
    padding: 0;
  }

  fieldset {
    border: none;
  }
  div {
    //padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    //margin-right: 20px;
    box-shadow: none;
    border-radius: 1px;
  }
}

#Client-Status-Select {
  .MuiChip-colorSuccess {
    color: var(--google-green);
    //border: 1px solid var(--google-green);
    background-color: var(--google-light-green);
  }
  .MuiChip-colorError {
    color: var(--google-red);
    background-color: var(--google-light-red);
  }
}

#ClientItem-td__status-select__success {
  .MuiChip-colorSuccess {
    color: var(--google-green);
    //border: 1px solid var(--google-green);
    background-color: var(--google-light-green);
  }
}

#ClientItem-td__status-select__error {

  .MuiChip-colorError {
    color: var(--google-red);
    background-color: var(--google-light-red);
  }
}

.CRM-action-button {
  color: rgba(136, 138, 139, 1);
  width: 100% !important;
  cursor: pointer;

  svg {
    height: 32px !important;
  }

}

.MiniLeadItem, .MiniClientItem {
  font-size: 10px;
  padding: 5px 10px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;;
  border-bottom-right-radius: 0 !important;;
  border-bottom-left-radius: 8px !important;;
  background: #F6F6F6;
  min-height: 30px;

  cursor: pointer;
  margin: 5px 10px;

  svg {
    visibility: hidden;
    margin-right: 5px;
    color: var(--danger-red);
  }


  &:hover {
    svg {
      visibility: visible;
    }
  }

  &:hover {
    //color: #FFFFFF;
    //background-color: var(--leaseLightBlue);
  }
  &.isPurchase {
    &:hover {
      background-color: var(--purchaseLightGreen);
    }

  }
}

.CRM__action-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(59, 62, 64, 1);

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: #F8F8F8;
  }
}

.ConvertToClientModal {
  padding: 20px 40px;

  .ConvertToClientItem {

    .ConvertToClient-div {
      height: 100%;
      padding: 5px;
      width: 100%;
      border-radius: 0;
      color: rgba(117, 117, 117, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.ConvertClientItem__status-select {
        background-color: rgba(246, 246, 246, 1);
      }
    }

    div.status_select {
      height: 100%;
      padding: 5px;
      width: 100%;
      border-radius: 0;
      color: rgba(117, 117, 117, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        height: 100%;
        padding: 5px;
        width: 100%;
        border-radius: 0;
        color: rgba(117, 117, 117, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    label {
      color: rgba(117, 117, 117, 1);
      font-weight: 400;
      font-size: 13px;
      leading-trim: Cap height;
      line-height: 24px;
      margin-right: 20px;
    }

    input {
      padding: 5px 10px;
      box-sizing: border-box;
      width: 374px;
      height: 44px;
      outline: none;
      border: 1px solid rgba(196, 196, 196, 1);
      background-color: rgba(246, 246, 246, 1);
      color: #000000;
    }
  }
}

.SelectMiniDealCard {
  font-size: 12px;
  padding: 5px 10px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;;
  border-bottom-right-radius: 0 !important;;
  border-bottom-left-radius: 8px !important;;
  background: #F6F6F6;

  cursor: pointer;
  margin: 5px 10px;

  svg {
    margin-right: 5px;
  }
}

.link-text {
  color: var(--leaseDarkBlue);
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.ClientItem-td__status-select__convert-client {
  width: 100%;
  input {
    padding: 0;
  }

  fieldset {
    border: none;
  }
  div {
    //padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    //margin-right: 20px;
    box-shadow: none;
    border-radius: 1px;
  }
}

.ClientItemProfitTable {
  th, td {
    border: 1px solid #E0E0E0;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    text-align: right;
  }

  td:focus-within {
    background: var(--background-light-blue);
  }

  th {
    background-color: #F8F8F8;
    color: #666666;
  }

  tr.total-row {
    td {
      font-weight: bold;
    }
  }
}

.ClientItem-td, .CreateClientItem-th {
  .MuiBadge-badge {
    background-color: var(--google-light-red);
  }

  &.isPaid {
    .MuiBadge-badge {
      background-color: var(--google-light-green);
    }
  }
}

.CRMTableTabs {
  width: calc(100% - 60px);
  border-top: 1px solid #E0E0E0;

  .MuiTabs-root {
    width: 100%;
  }

  .CRMTableTabs__year {
    font-size: 14px;
    font-weight: 600;
    padding: 12px 16px;
    line-height: 17.5px;
    color: #666666;
    cursor: pointer;
    &:hover {
      background-color: #F8F8F8;
    }
  }

  .MuiTabs-indicator {
    top: 0;
  }
}

.ClientItem-datepicker {
  z-index: 2 !important;
}

.react-datepicker__header {
  background-color: var(--background-light-blue) !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  color: var(--background-light-blue) !important;;
  fill: var(--background-light-blue) !important;;
}
